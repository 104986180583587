import { Color, SimpleColor } from 'types/color.type';
import {
    OpacityAnimation,
    PositionAnimation,
    RotationAnimation,
    ScaleAnimation,
    ShadowAnimation,
    SizeAnimation,
    ColorAnimation,
    PredefinedAnimationValue
} from './animation.type';
import { FontType } from './font.type';
import { LayerHover } from './hover.type';
import Src from './src.type';
import Value from './value.type';

export enum PositionUnitOptions {
    Px = 'px',
    '%' = '%'
}
export enum SizeUnitOptions {
    Px = 'px',
    '%' = '%'
}
export enum PositionOptions {
    Absolute = 'absolute',
    Relative = 'relative',
    Static = 'static'
}
export enum MeasurePointOptions {
    NW = 'nw',
    NE = 'ne',
    SE = 'se',
    SW = 'sw'
}
export enum HorizontalAlignOptions {
    Left = 'left',
    Center = 'center',
    Right = 'right'
}
export enum VerticalAlignOptions {
    Top = 'top',
    Middle = 'middle',
    Bottom = 'bottom'
}
export enum BorderStyleOptions {
    None = 'none',
    Solid = 'solid',
    Dashed = 'dashed',
    Dotted = 'dotted',
    Double = 'double'
}
export enum BorderSideOptions {
    Left = 'left',
    Right = 'right',
    Top = 'top',
    Bottom = 'bottom'
}
export enum TextBorderStyleOptions {
    None = 'none',
    Solid = 'solid'
}
export enum TextShadowStyleOptions {
    None = 'none',
    Initial = 'outside'
}
export enum ShadowStyleOptions {
    None = 'none',
    Initial = 'initial',
    Inset = 'inset'
}
export enum OverflowOptions {
    Visible = 'visible',
    Hidden = 'hidden'
}
export enum FontSizeUnitOptions {
    Px = 'px',
    Em = 'em',
    Vw = 'vw',
    Vh = 'vh'
}

export enum PaddingUnitOptions {
    Px = 'px',
    Em = 'em',
    '%' = '%'
}
export enum TextTransformOptions {
    None = 'none',
    Uppercase = 'uppercase',
    Lowercase = 'lowercase',
    Capitalize = 'capitalize'
}
export enum TextAlignHorizontalOptions {
    Left = 'left',
    Center = 'center',
    Right = 'right',
    Justify = 'justify'
}
export enum TextAlignVerticalOptions {
    FlexStart = 'flex-start',
    Center = 'center',
    FlexEnd = 'flex-end'
}
export enum TextDecorationOptions {
    None = 'none',
    Underline = 'underline',
    LineThrough = 'line-through',
    Overline = 'overline'
}
export enum TextDecorationStyleOptions {
    Solid = 'solid',
    Double = 'double',
    Dotted = 'dotted',
    Dashed = 'dashed',
    Wavy = 'wavy'
}
export enum MediaRepeatOptions {
    Repeat = 'repeat',
    NoRepeat = 'no-repeat'
}
export enum MediaPositionOptions {
    LeftTop = 'left top',
    LeftCenter = 'left center',
    LeftBottom = 'left bottom',
    RightTop = 'right top',
    RightCenter = 'right center',
    RightBottom = 'right bottom',
    CenterTop = 'center top',
    CenterCenter = 'center center',
    CenterBottom = 'center bottom'
}
export enum MediaSizeOptions {
    Fill = 'fill',
    Cover = 'cover',
    Contain = 'contain'
}
export enum MediaAltOptions {
    None = 'none',
    Decorative = 'decorative',
    Custom = 'custom',
    TitleOfAsset = 'titleOfAsset'
}
export enum SmartLayoutDirectionOptions {
    Column = 'column',
    Row = 'row'
}
export enum SmartLayoutAlignOptions {
    FlexStart = 'flex-start',
    Center = 'center',
    FlexEnd = 'flex-end'
}
export enum SmartLayoutJustifyOptions {
    FlexStart = 'flex-start',
    Center = 'center',
    FlexEnd = 'flex-end',
    SpaceBetween = 'space-between',
    SpaceEvenly = 'space-evenly',
    SpaceAround = 'space-around'
}
export enum SmartLayoutGapUnitOptions {
    Px = 'px',
    Em = 'em',
    Vw = 'vw',
    Vh = 'vh'
}
export enum BlendModeOptions {
    Normal = 'normal',
    Multiply = 'multiply',
    Screen = 'screen',
    Overlay = 'overlay',
    Darken = 'darken',
    Lighten = 'lighten',
    ColorDodge = 'color-dodge',
    ColorBurn = 'color-burn',
    HardLight = 'hard-light',
    SoftLight = 'soft-light',
    Difference = 'difference',
    Exclusion = 'exclusion',
    Hue = 'hue',
    Saturation = 'saturation',
    Color = 'color',
    Luminosity = 'luminosity'
}
export enum ShadowPlacementOptions {
    Box = 'box',
    Image = 'image'
}
export enum ResizeOptions {
    Fixed = 'fixed',
    Hug = 'hug',
    Fill = 'fill'
}

export interface xyOffset {
    xOffset: number;
    yOffset: number;
}
export interface Media {
    src: Src | boolean;
    size: MediaSizeOptions;
    position: MediaPositionOptions;
    repeat: MediaRepeatOptions;
    alt?: string;
    altType?: MediaAltOptions;
}
export interface BorderRadius {
    topLeft: Value<number>;
    topRight: Value<number>;
    bottomRight: Value<number>;
    bottomLeft: Value<number>;
}

export enum BorderRadiusUnitOptions {
    Px = 'px',
    '%' = '%'
}

export interface Border {
    borderWidth: {
        left: Value<number>;
        right: Value<number>;
        top: Value<number>;
        bottom: Value<number>;
    };
    borderColor: SimpleColor;
    borderStyle: BorderStyleOptions;
}
export interface Shadow {
    shadowStyle: ShadowStyleOptions;
    color: SimpleColor;
    xOffset: number;
    yOffset: number;
    blur: number;
    spread: number;
}
export interface TextShadow {
    shadowStyle: ShadowStyleOptions;
    color: SimpleColor;
    xOffset: number;
    yOffset: number;
    blur: number;
}
export interface TextBorder {
    borderStyle: TextBorderStyleOptions;
    borderWidth: Value<number>;
    color: SimpleColor;
}
export interface CanEdit {
    draggable: boolean;
    draggableX: boolean;
    draggableY: boolean;
    resizable: boolean;
    resizableLocked: boolean;
}

export interface CanEditWithScaleText extends CanEdit {
    scaleText: boolean;
}

export interface CanEditWithDefaultHidden extends CanEdit {
    defaultHidden: boolean;
}

export interface TextStyling {
    color: Color;
    fontSize: Value<number>;
    lineHeight: number;
    letterSpacing: number;
    fontFamily: string;
    fontSource: FontType;
    fontVariant: string;
    textTransform: TextTransformOptions;
    textAlignHorizontal: TextAlignHorizontalOptions;
    textAlignVertical: TextAlignVerticalOptions;
    textDecoration: TextDecorationOptions;
    textDecorationStyle: TextDecorationStyleOptions;
    textShadow: TextShadow;
    textBorder: TextBorder;
    placeholder?: boolean;
    fontName?: string;
}

export interface Properties {
    canEdit: CanEdit;
    measurePoint: MeasurePointOptions;
    horizontalAlign: HorizontalAlignOptions | null;
    verticalAlign: VerticalAlignOptions | null;
    width: Value<number | string> & { resize: ResizeOptions };
    height: Value<number | string> & { resize: ResizeOptions };
    maxWidth: Value<number | string>;
    maxHeight: Value<number | string>;
    scale: number;
    position: PositionOptions;
    x: Value<number | string>;
    y: Value<number | string>;
    lockedSize: boolean;
    rotation: number;
    skew: xyOffset;
    transformOrigin: xyOffset;
    background: Color;
    backgroundImage: Media;
    blendMode: BlendModeOptions;
    customCSS: string;
    padding: {
        top: Value<number>;
        right: Value<number>;
        bottom: Value<number>;
        left: Value<number>;
    };
    margin: {
        top: Value<number>;
        right: Value<number>;
        bottom: Value<number>;
        left: Value<number>;
    };
    opacity: number;
    display: boolean;
    borderRadius: BorderRadius;
    border: Border;
    shadow: Shadow;
    overflow: OverflowOptions;
    customClassname: string;
}

export interface TextProperties extends Properties {
    text: string;
    textBackground: boolean;
    textStyling: {
        normal: TextStyling;
        highlighted: TextStyling;
    };
    canEdit: CanEditWithScaleText;
}

export type ShapeProperties = Properties;

export interface MediaProperties extends Properties {
    media: Media;
}

export interface ImageProperties extends MediaProperties {
    canEdit: CanEditWithDefaultHidden;
    shadowPlacement: ShadowPlacementOptions;
}

export interface VideoProperties extends MediaProperties {
    volumeOff: boolean;
    hideWhenFinished: boolean;
    canEdit: CanEditWithDefaultHidden;
}

export interface AudioProperties extends Properties {
    /**
     * @deprecated - It is used for old templates. Use `media` instead.
     */
    src: Src | null;
    media: Media;
    volume: number;
    loop: boolean;
}

export interface LottieProperties extends Properties {
    lottieAnimation: {
        url: string;
        extension: string;
        title: string;
    };
}

export interface ContainerProperties extends Properties {
    smartLayout: boolean;
    direction: SmartLayoutDirectionOptions;
    align: SmartLayoutAlignOptions;
    justify: SmartLayoutJustifyOptions;
    columnGap: Value<number>;
    rowGap: Value<number>;
}

export type AllProperties = TextProperties | ShapeProperties | ImageProperties | VideoProperties | AudioProperties | LottieProperties | ContainerProperties;

export type PredefinedAnimationsKey = `id__${string}`;

export interface Animations {
    color?: ColorAnimation[] | null;
    backgroundColor?: ColorAnimation[] | null;
    opacity?: OpacityAnimation[] | null;
    position?: PositionAnimation[] | null;
    rotation?: RotationAnimation[] | null;
    scale?: ScaleAnimation[] | null;
    shadow?: ShadowAnimation[] | null;
    size?: SizeAnimation[] | null;
}

export interface PredefinedAnimations {
    [key: PredefinedAnimationsKey]: [PredefinedAnimationValue, PredefinedAnimationValue] | null | undefined;
}

export type CustomAndPredefinedAnimations = Animations & PredefinedAnimations;
export type Visibility = [number, number];

interface LayerProperties {
    animations?: CustomAndPredefinedAnimations;
    hover: LayerHover;
    properties: AllProperties;
    visibility?: Visibility;
}

export default LayerProperties;
