import { SimpleColor } from 'types/color.type';
import { Keyframe } from '../components/timeline/components/tracks/components/keyframe';
import Layer from './layer.type';
import { Shadow } from './layerProperties.type';
import { State } from './template.type';
import Value from './value.type';

export type ActiveAnimation = State['activeAnimations'][0] | null;

export enum ActiveAnimationType {
    Visibility = 'visibility',
    PredefinedAnimation = 'predefinedAnimation',
    Keyframe = 'keyframe'
}

export type AnimationOptions = 'position' | 'size' | 'scale' | 'rotation' | 'opacity' | 'backgroundColor' | 'color' | 'shadow';
export type PredefinedAnimationOptions =
    | 'bungee'
    | 'fadeIn'
    | 'fadeOut'
    | 'heartbeat'
    | 'pulse'
    | 'scaleIn'
    | 'scaleOut'
    | 'wiggle'
    | 'slideIn'
    | 'slideOut'
    | 'shake'
    | 'backIn'
    | 'popUp'
    | 'rotateIn'
    | 'rotateOut'
    | 'growOut';
export type TimingFunction = 'linear' | 'ease' | 'ease-in' | 'ease-out' | 'ease-in-out' | 'custom';

export interface Animation {
    id: string;
    stamp: number;
    predefinedAnimationKey?: string;
    easing: {
        type: TimingFunction;
        value: string | number[];
    };
}

export interface PositionAnimation extends Animation {
    value: {
        x: Value<number>;
        y: Value<number>;
    };
}

export interface PositionAnimationPredefined extends Animation {
    value: {
        x?: Value<number>;
        y?: Value<number>;
    };
}

export interface BackgroundColorAnimation extends Animation {
    value: SimpleColor;
}

export interface ColorAnimation extends Animation {
    value: SimpleColor;
}

export interface OpacityAnimation extends Animation {
    value: number;
}

export interface RotationAnimation extends Animation {
    value: {
        transformOrigin: {
            xOffset: number;
            yOffset: number;
        };
        value: number;
    };
}

export interface ScaleAnimation extends Animation {
    value: {
        value: number;
        transformOrigin: {
            xOffset: number;
            yOffset: number;
        };
    };
}

export interface ShadowAnimation extends Animation {
    value: Shadow;
}

export interface SizeAnimation extends Animation {
    value: {
        width: Value<number>;
        height: Value<number>;
        locked?: boolean;
    };
}

export type AnimationValue =
    | BackgroundColorAnimation
    | OpacityAnimation
    | PositionAnimation
    | RotationAnimation
    | ScaleAnimation
    | ShadowAnimation
    | SizeAnimation
    | ColorAnimation;

//Example of a full predefined animation
// {
//     id: string;
//     stamp: number;
/* All data below is identical for both keyframes of the predefined animations  */
//     type: PredefinedAnimationOptions;
//     index: number;
//     easing: {
//         type: TimingFunction;
//         value: string;
//     };
//     value: {
//         position: {
//             y: Value<number>;
//             direction: 'up' | 'down' | 'left' | 'right' | 'degrees';
//             directionDegrees: number;
//         };
//     };
// }

export interface PredefinedSetupScaleAnimation extends Animation {
    value: {
        value: number | 'initial';
        transformOrigin: {
            xOffset: number;
            yOffset: number;
        };
    };
}

export interface PredefinedAnimation extends Animation {
    type: PredefinedAnimationOptions;
    index: number;
}

export interface PredefinedPositionAnimation extends PredefinedAnimation {
    value: {
        position: {
            y: Value<number>;
            direction: 'up' | 'down' | 'left' | 'right' | 'degrees';
            directionDegrees: number;
        };
        loop?: {
            value: number;
        };
    };
}

export interface PredefinedRotationAnimation extends PredefinedAnimation {
    value: {
        rotation: {
            value: number;
            transformOrigin: {
                xOffset: number;
                yOffset: number;
            };
            direction: 'right' | 'left';
        };
    };
}

export interface PredefinedLoopAnimation extends PredefinedAnimation {
    value: {
        loop: {
            value: number;
        };
    };
}

export interface PredefinedScaleAnimation extends PredefinedAnimation {
    value: {
        scale: {
            value: number;
        };
    };
}

export interface OtherAnimations extends PredefinedAnimation {
    value?: {
        value: {
            value: number;
        };
    };
}
export type PredefinedAnimationValue =
    | PredefinedPositionAnimation
    | PredefinedRotationAnimation
    | PredefinedLoopAnimation
    | PredefinedScaleAnimation
    | OtherAnimations;

export type AllPredefinedAnimationsValues = PredefinedPositionAnimation &
    PredefinedRotationAnimation &
    PredefinedLoopAnimation &
    PredefinedScaleAnimation &
    OtherAnimations;

export type PredefinedAnimationSetupValue =
    | BackgroundColorAnimation
    | OpacityAnimation
    | PositionAnimationPredefined
    | RotationAnimation
    | ScaleAnimation
    | ShadowAnimation
    | SizeAnimation
    | ColorAnimation
    | PredefinedSetupScaleAnimation;

export type AllAnimationValue = PredefinedAnimationValue | AnimationValue;
export interface KeyframesInDom {
    layer: Layer['key'];
    attribute: string;
    id: Animation['id'];
    predefined: string;
}

export type Keyframe = {
    layer: string | undefined;
    attribute: string | undefined;
    id: string | undefined;
    predefined: string | undefined;
};

export type KeyframeDataset = {
    attribute: string;
    keyframeIndex: string;
    layer: string;
    stamp: string;
    stampId: string;
};

export default Animation;
